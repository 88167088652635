import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-richardson-texas.png'
import image0 from "../../images/cities/scale-model-of-downtown-plano-in-richardson-texas.png"
import image1 from "../../images/cities/scale-model-of-heritage-farmstead-museum-in-richardson-texas.png"
import image2 from "../../images/cities/scale-model-of-dark-hour-haunted-house-in-richardson-texas.png"
import image3 from "../../images/cities/scale-model-of-celestial-park-in-richardson-texas.png"
import image4 from "../../images/cities/scale-model-of-winnwood-park-in-richardson-texas.png"
import image5 from "../../images/cities/scale-model-of-travel-experience-international-in-richardson-texas.png"
import image6 from "../../images/cities/scale-model-of-garland-in-richardson-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Richardson'
            state='Texas'
            image={image}
            lat='32.9723'
            lon='-96.7081'
            attractions={ [{"name": "Downtown Plano", "vicinity": "1037 E 15th St, Plano", "types": ["point_of_interest", "establishment"], "lat": 33.0198482, "lng": -96.69954999999999}, {"name": "Heritage Farmstead Museum", "vicinity": "1900 W 15th St, Plano", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.018865, "lng": -96.731065}, {"name": "Dark Hour Haunted House", "vicinity": "701 Taylor Dr, Plano", "types": ["point_of_interest", "establishment"], "lat": 33.0095482, "lng": -96.70437279999999}, {"name": "Celestial Park", "vicinity": "5501 Celestial Rd, Dallas", "types": ["park", "point_of_interest", "establishment"], "lat": 32.9478009, "lng": -96.81332929999996}, {"name": "Winnwood Park", "vicinity": "5580 Belt Line Rd, Dallas", "types": ["park", "point_of_interest", "establishment"], "lat": 32.9532856, "lng": -96.81142390000002}, {"name": "Travel Experience International", "vicinity": "10300 N Central Expy # 170, Dallas", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.8859853, "lng": -96.7688197}, {"name": "Garland", "vicinity": "200 N 5th St, Garland", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.9137262, "lng": -96.63608970000001}] }
            attractionImages={ {"Downtown Plano":image0,"Heritage Farmstead Museum":image1,"Dark Hour Haunted House":image2,"Celestial Park":image3,"Winnwood Park":image4,"Travel Experience International":image5,"Garland":image6,} }
       />);
  }
}